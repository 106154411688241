






































































import { required, minLength, sameAs, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { Component, Watch, Vue } from "vue-property-decorator";
import AuthService from "@/services/auth";
import { LoginRequest, RegisterRequest } from "@/services/auth";

@Component({
  mixins: [validationMixin],
})
export default class Auth extends Vue {
  validationPending: Boolean = false;
  validEmailInvitePair: Boolean = false;
  showPassword: Boolean = false;
  login: Boolean = true;
  register: Boolean = false;
  loginFailure: Boolean = false;

  alert: Boolean = false;
  alertMessage: string = "";

  email: string = "";
  invite: string = "";

  password: string = "";
  repeatPassword: string = "";

  validations() {
    const inviteCode: string = this.invite.toString();
    const register: Boolean = this.register;

    var commonValidators: any = {
      email: {
        required,
        email,
      },
      password: { required },
    };

    if (register) {
      commonValidators = {
        ...commonValidators,
        repeatPassword: { sameAsPassword: sameAs("password") },
        password: {
          ...commonValidators.password,
          minLength: minLength(8),
        },
        email: {
          ...commonValidators.email,
          async isValidInvitePair(value: string) {
            if (value === "") return true;
            return new Promise(async function (resolve) {
              await AuthService.validate(value, inviteCode)
                .then((response) => {
                  resolve(true);
                })
                .catch((error) => {
                  resolve(false);
                });
            });
          },
        },
      };
    }

    return commonValidators;
  }

  get emailErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.email.$dirty) return errors;
    if (this.register) {
      !this.$v.email.isValidInvitePair &&
        !this.$v.email.$pending &&
        errors.push("Invite for this email is invalid");
    }
    !this.$v.email.required && errors.push("Email is required.");
    !this.$v.email.email && errors.push("Please enter a valid email address.");

    return errors;
  }

  get passwordErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.password.$dirty) return errors;
    if (this.register) {
      !this.$v.password.minLength &&
        errors.push("Password should be at least 8 characters long.");
    }
    !this.$v.password.required && errors.push("Please enter a password.");
    return errors;
  }

  get repeatPasswordErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.repeatPassword.$dirty) return errors;
    !this.$v.repeatPassword.sameAsPassword &&
      errors.push("Passwords must be identical.");
    return errors;
  }

  registerUser() {
    this.$v.$touch();
    if (this.$v.$error) return;

    const registerRequest: RegisterRequest = this.parseRegisterFormData();
    this.$store
      .dispatch("auth/register", registerRequest)
      .then((response) => console.log(response))
      .then((_) => this.$router.push("/home"))
      .catch((err) => console.log(err));
  }

  loginUser() {
    this.$v.$touch();
    if (this.$v.$error) return;

    const loginRequest: LoginRequest = this.parseLoginFormData();
    this.$store
      .dispatch("auth/login", loginRequest)
      .then(
        (response) => {
          console.log(response);
        },
        (error) => {
          this.alertMessage = "Email or Password incorrect";
          this.alert = true;
          console.log("Unauthorized in component");
          return Promise.reject(error);
        }
      )
      .then((_) => {
        // It is possible that the query parameters were updated after the created() function was called
        // which is why I reference the redirectUrl query parameter directly here.
        if(this.$route.query.redirectUrl && this.$route.query.redirectUrl !== "") {
            // We're using this method of redirection because the redirect could be on another domain. 
            window.location.href = `${this.$route.query.redirectUrl as string}`
        } else {
            // Router push is used because home is on the same domain.
            this.$router.push("/home")
        }
      })
      .catch((err) => console.log(err));
  }

  parseRegisterFormData(): RegisterRequest {
    return new RegisterRequest(this.email, this.password, this.invite);
  }

  parseLoginFormData(): LoginRequest {
    return new LoginRequest(this.email, this.password);
  }

  clearForm() {
    this.$v.$reset();
    this.email = "";
    this.password = "";
    this.repeatPassword = "";
  }

  toggleAlert() {
    this.alert = false;
    this.alertMessage = "";
  }

  created() {
    const quereyParams = this.$route.query;
    const inviteJsonEncoded = quereyParams.invite;

    if (inviteJsonEncoded) {
      const inviteJsonDecoded: any = JSON.parse(
        atob(inviteJsonEncoded.toString())
      );

      this.email = inviteJsonDecoded.email;
      this.invite = inviteJsonDecoded.inviteCode;

      this.register = true;
      this.login = false;
    } else {
      this.register = false;
      this.login = true;
    }
  }

  @Watch("$v.email.$pending")
  onPropertyChanged(value: Boolean) {
    if (this.register) {
      this.validationPending = value;
      this.validEmailInvitePair = !this.$v.email.$invalid;
    }
  }

  @Watch("$route")
  onRouteRedirect() {
    console.log("route redirected");
    this.validationPending = false;
    this.showPassword = false;
    this.login = true;
    this.register = false;

    this.email = "";
    this.invite = "";

    this.password = "";
    this.repeatPassword = "";
  }
}
